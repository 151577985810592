import { IData } from "./data-types";
// @ts-ignore
import betaHero from "../images/join_beta.svg";
// @ts-ignore
import smallPersonalized from "../images/small_personalized.svg";
// @ts-ignore
import anonymous from "../images/anonymous.svg";
// @ts-ignore
import asynchronous from "../images/asynchronous.svg";
// @ts-ignore
import privacy from "../images/privacy.svg";
import { BetaBody, BetaMission, BetaTitle } from "../components/layoutElements";
import React from "react";

const betaData: IData = {
  title: "Beta",
  hero: {
    text_body: BetaBody,
    image_src: betaHero,
    text_title: BetaTitle,
    text_lower_title: "A dedicated space to chat and learn from peers.",
  },
  joinBeta: {
    text_title: "Chat with fellow providers from anywhere.",
    text_body: BetaMission,
  },
  ourPromise: {
    text_title: "Our Promise",
    text_body: "",
    cards: [
      {
        text_title: "Small and Personalized Groups",
        text_body:
          "Peers chatting with peers in small groups around common interest and drivers.",
        image_src: smallPersonalized,
      },
      {
        text_title: "Anonymous",
        text_body:
          "Talk and share, freely and naturally. You will be able to digest, debrief and even grieve.",
        image_src: anonymous,
      },
      {
        text_title: "Asynchronous Chat",
        text_body:
          "Our product will always be transparent and respectful with what is collected, while still enabling the user’s voice to help create solutions.",
        image_src: asynchronous,
      },
      {
        text_title: "Privacy",
        text_body:
          "Our product will always be transparent and respectful with what is collected.",
        image_src: privacy,
      },
    ],
  },
};

export { betaData };
